<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/agent.png" alt />
        <span class="center">代理管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">业务人员</span>
      </div>
      <div>
        <el-button
          size="small"
          @click="addUser('group')"
          type="primary"
          icon="el-icon-plus"
          v-access
          data-ctrl="platAddGroup"
        >新建代理公司</el-button>
        <el-button
          size="small"
          @click="addUser('user')"
          type="primary"
          icon="el-icon-plus"
          v-access
          data-ctrl="platAddUser"
        >新建经纪人</el-button>
        <el-button
          size="small"
          @click="addUser('user')"
          type="primary"
          icon="el-icon-plus"
          v-access
          data-ctrl="GroupAddUser"
        >新建经纪人(代理公司)</el-button>
      </div>
    </div>
    <el-card>
      <ListFilter :total="total" @close="putAway">
        <div class="summary">
          <span>关键词：</span>
          <el-input
            v-model="keywords"
            placeholder="业务员人员"
            size="mini"
            style="margin-right: 10px; width: 300px"
            prefix-icon="el-icon-search"
            @input="getSearch()"
          ></el-input>
          <!-- <span>角色：</span>
          <el-select size="mini"
                     v-model="roles"
                     placeholder="请选择"
                     @change="loadData('seath')">
            <el-option :label="item.label"
                       :value="item.value"
                       v-for="(item, i) in rolesList"
                       :key="i">
            </el-option>
          </el-select>-->
        </div>
      </ListFilter>
      <!-- 一级代理创建子用户 -->
      <!-- <AddSubuser
      :dialogFormVisible.sync="addUserShow"
      :role="'agent_root'"
      @close="loadData"
      ></AddSubuser>-->
      <!-- 二级代理创建子用户 -->
      <!-- <AddSubuser
        :dialogFormVisible.sync="agentVisible"
        :role="'enterprise_agent'"
        @close="loadData"
      ></AddSubuser>-->
      <!-- 平台创建代理组或代理用户 -->
      <AddUser :dialogFormVisible.sync="addUserShow" :type="type" @close="loadData"></AddUser>
      <el-table :data="tableData" style="width: 100%；">
        <el-table-column fixed prop="name" label="业务人员 & 帐号" width="300px">
          <template scope="scope">
            <div
              style="
                color: #409eff;
                text-decoration: underline;
                cursor: pointer;
              "
              @click="getMore(scope.row)"
            >
              <span v-if="scope.row.name">{{ scope.row.name }}</span>
              <span v-else>{{ scope.row.code }}</span>
            </div>
            <div>{{ scope.row.username }}</div>
          </template>
        </el-table-column>
        <el-table-column label="电话号码">
          <template scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>

        <el-table-column label="角色">
          <template scope="scope">{{ scope.row.roles | rolesVal }}</template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template scope="scope">
            {{
            scope.row.create_time | dateVal
            }}
          </template>
        </el-table-column>
        <el-table-column label="path">
          <template scope="scope">{{ scope.row.agentPath }}</template>
        </el-table-column>
        <el-table-column label="是否绑定微信">
          <template scope="scope">
            <div v-if="scope.row.roles === 'agentUser'">
              <div v-if="scope.row.openid">与{{ scope.row.nickname }}绑定</div>
              <div v-if="!scope.row.openid">未绑定</div>
            </div>
            <div v-else>—</div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="" label="当月业绩">
        <template scope="scope">
          {{ scope.row.monthTaskTotalVal | money }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="总业绩">
        <template scope="scope">
          {{ scope.row.taskTotalVal | money }}
        </template>
      </el-table-column>
      <el-table-column label="我的客户">
        <template scope="scope">
          {{ scope.row.enterpriseTotalVal }}
        </template>
      </el-table-column>
      <el-table-column label="本月新增企业">
        <template scope="scope">
          {{ scope.row.monthEnterpriseTotalVal }}
        </template>
      </el-table-column>
      <el-table-column label="所属企业" width="280px" show-overflow-tooltip>
        <template scope="scope">
          {{ scope.row.enterpriseName }}
        </template>
        </el-table-column>-->
        <!-- <el-table-column label="所属分组" width="280px" show-overflow-tooltip>
          <template scope="scope">
            {{ scope.row.groupName }}
          </template>
        </el-table-column>-->
        <!-- <el-table-column prop="" label="入驻时间" width="180px">
        <template scope="scope">
          {{ scope.row.createTime }}
        </template>
        </el-table-column>-->
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.roles !== 'agentUser'"
              @click="copy(scope.row.invitation)"
              type="text"
              size="small"
            >复制邀请链接</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button @click="delRow(scope.row)" style="margin:0" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="交接维护人员" :visible.sync="outerVisible" @close="closeDialog">
      <div class="dialog-top">
        <el-row :gutter="24" style="width: 100%">
          <el-col :span="12" class="text-align">
            <div>
              <span>当前维护人员：</span>
              <span>{{ maintenanceStaff }}</span>
            </div>
          </el-col>
          <el-col :span="12" class="text-align">
            <div>
              <span>交接人员姓名：</span>
              <el-select v-model="personnel" placeholder="请选择" size="mini">
                <el-option
                  v-for="item in personnelList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                  :disabled="item.code === code"
                ></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="btn-border">
          <el-popconfirm
            title="当前维护人员下的用户、企业、及代理企业均转移到新代理下，确定操作吗？"
            @confirm="replacement"
            @cancel="closeDialog"
          >
            <el-button slot="reference" type="primary">确认更换，并删除</el-button>
          </el-popconfirm>
          <el-button style="margin-left:20px" @click="closeDialog">取 消</el-button>
        </div>
      </div>
    </el-dialog>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { api } from '/src/api/base';
// import config from '../../config/config.js';
export const agentUserListAccount = api()('agent.user.list.account.json');
export const agentCustomerTransfer = api()('agent.customer.transfer.json');
export default {
  data () {
    return {
      keywords: '',
      roles: 'agentGroup,agentUser,agentDomain',

      rolesList: [
        {
          label: '全部',
          value: 'agentGroup,agentUser,agentDomain',
        },
        {
          label: '代理分组',
          value: 'agentGroup',
        },
        {
          label: '代理成员',
          value: 'agentUser',
        },
      ],
      tableData: [],
      personnel: '',
      maintenanceStaff: '',
      agentVisible: false,
      value: '',
      outerVisible: false,
      code: '',
      personnelList: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      addUserShow: false,
      type: '',
    };
  },
  created () {
    this.loadData();
  },
  components: {
    ListFilter: () => import('/src/components/filter'),
    // AddSubuser: () => import("../setting/subuser/addSubuser"),
    AddUser: () => import('./addUser'),
  },
  methods: {
    async getTransferList () {
      const { list } = await agentUserListAccount({
        pageNumber: 1,
        pageSize: 1000,
      });
      this.personnelList = list;
    },
    addUser (type) {
      this.addUserShow = true;
      this.type = type;
    },
    async delRow (row) {
      this.maintenanceStaff = row.name;
      this.code = row.code;
      this.outerVisible = true;
      await this.getTransferList();
    },
    async replacement () {
      if (!this.personnel) {
        return this.$message.error('请先选择新的交接人');
      }
      await agentCustomerTransfer({
        oldUserCode: this.code,
        newUserCode: this.personnel,
      });

      this.outerVisible = false;
      this.loadData();
    },
    closeDialog () {
      this.personnel = '';
      this.outerVisible = false;
    },
    copy (data) {
      let url = window.location.origin + '/web/register?invitation=' + data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand('Copy');
      this.$message({
        message: '复制成功',
        type: 'success',
      });
      oInput.remove();
    },
    getSearch () {
      this.pageNumber = 1;
      this.loadData();
    },
    putAway () {
      this.pageNumber = 1;
      this.loadData();
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    async loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        roles: this.roles,
        keywords: this.keywords,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        $ignoreRepeat: true,
      };
      await agentUserListAccount(params).then((res) => {
        this.tableData = res.list;
        this.total = res.total;
      });
    },
    getMore (row) {
      this.$router.push({
        path: '/system/agent/personnelDetails',
        query: {
          code: row.code,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.summary {
  display: flex;
  align-items: center;
}
.btn {
  padding: 5px 20px;
  background: rgb(242 242 242);
  border-radius: 4px;
  margin: 0 20px;
}
.dialog-top {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-center {
  padding: 20px;
  display: flex;
  align-items: center;
}
.btn-border {
  display: flex;
  justify-content: flex-end;
}
</style>
